import React from 'react';

const SvgPaddingTop = ({width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <path
            fill="#D9DFFB"
            transform={`scale(${width / 24})`}
            d="M18 17a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1z"
        />
        <mask id="padding-top_svg__a" fill="#fff">
            <path d="M16 8H8V6h8z" />
        </mask>
        <path
            fill={'#39A4F3'}
            d="M16 8v2h2V8zM8 8H6v2h2zm0-2V4H6v2zm8 0h2V4h-2zm0 0H8v4h8zm-6 2V6H6v2zM8 8h8V4H8zm6-2v2h4V6z"
            mask="url(#padding-top_svg__a)"
        />
    </svg>
);
export default SvgPaddingTop;
