import React from 'react';
import {IMAGE_FIELD_ALIGNMENT_OPTIONS} from 'WEBFORMS/config';

/**
 * @param {object} props - The props of the ImageField component.
 * @param {string | null} [props.url] - The URL of the image field.
 * @param {string} [props.alignment] - The alignment of the image field.
 * @returns {React.ReactNode} - The ImageField component.
 */
const ImageField = ({url, alignment}) => {
    /**
     * @param {string} alignment - The alignment of the image field.
     * @returns {string} - The class name of the alignment.
     */
    const getAlignmentClassName = (alignment) => {
        switch (alignment) {
            case IMAGE_FIELD_ALIGNMENT_OPTIONS.left:
                return 'image-field-left';
            case IMAGE_FIELD_ALIGNMENT_OPTIONS.right:
                return 'image-field-right';
            default:
                return '';
        }
    };

    const alignmentClassName = getAlignmentClassName(alignment ?? '');

    return <img className={`image-field ${alignmentClassName}`} src={url ?? ''} alt="" />;
};

export default ImageField;
