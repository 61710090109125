import React from 'react';

const SvgContainer = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <path
            transform={`scale(${width / 24})`}
            fill={color}
            d="M4.5 7.5C4.5 6.7 5.2 6 6 6h3v1.5H6v2.25H4.5zM6 18c-.8 0-1.5-.7-1.5-1.5v-2.25H6v2.25h3V18zM19.5 16.5c0 .8-.7 1.5-1.5 1.5h-3v-1.5h3v-3h1.5zM18 6c.8 0 1.5.7 1.5 1.5v3H18v-3h-3V6z"
        />
    </svg>
);
export default SvgContainer;
