const baseTextFonts = [
    'Arial',
    'sans-serif',
    'serif',
    'monospace',
    'Arial Black',
    'Arial Narrow',
    'Times New Roman',
    'Comic Sans MS',
    'Verdana',
    'Garamond',
    'Trebuchet MS',
    'Georgia',
    'Tahoma',
    'Roboto',
];

const inheritFont = 'inherit';

const baseFontsWithInherit = [...baseTextFonts, inheritFont];

/**
 * @param {string} size - The size to convert.
 * @param {string} toUnit - The unit to convert to.
 * @returns {string} - The converted value.
 */
export const convertFontSize = (size, toUnit) => {
    if (!size) return '';

    const currentUnit = size.includes('pt') ? 'pt' : 'px';
    const currentSize = Number(size.replace(currentUnit, ''));

    if (currentUnit === toUnit) return size;

    /** @type {Record<string, number>} */
    const CONVERT_RATIO = {
        'px-pt': 0.75,
        'pt-px': 1.33,
    };

    const currentRatio = CONVERT_RATIO[`${currentUnit}-${toUnit}`];

    return Number.isNaN(currentSize) || !currentRatio ? size : `${Math.round(currentSize * currentRatio)}${toUnit}`;
};

export const fonts = {baseTextFonts, baseFontsWithInherit, convertFontSize};
