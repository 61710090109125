import React from 'react';

const SvgOneColumn = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <path
            opacity="0.3"
            fill={color}
            transform={`scale(${width / 24})`}
            d="M5 6a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1z"
        />
    </svg>
);
export default SvgOneColumn;
