import React from 'react';

const SvgOneToThreeColumns = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g opacity="0.3" transform={`scale(${width / 24})`} fill={color}>
            <path d="M5 6a1 1 0 0 1 1-1h2v14H6a1 1 0 0 1-1-1zM9 5h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H9z" />
        </g>
    </svg>
);
export default SvgOneToThreeColumns;
