import React from 'react';
import * as R from 'ramda';

export const FIELD_TYPES = Object.freeze({
    singleline: 'single-line-text',
    multiline: 'multi-line-text',
    checkbox: 'checkbox',
    dropdown: 'dropdown',
    hidden: 'hidden',
    number: 'number',
    email: 'email',
    richText: 'rich_text',
    image: 'image',
});

export const DECORATIVE_FIELD_TYPES = /** @type {string[]} */ ([FIELD_TYPES.richText, FIELD_TYPES.image]);

export const CHECKBOX_FIELD_KEYS = Object.freeze({
    fieldName: 'field_name',
    label: 'label',
    isRequired: 'required',
    defaultValue: 'default_value',
});

export const IMAGE_FIELD_KEYS = Object.freeze({
    fieldName: 'field_name',
    content: 'content',
    styling: 'styling',
});

export const IMAGE_FIELD_CONTENT_KEYS = Object.freeze({
    downloadUrl: 'download_url',
    sid: 'sid',
});

export const IMAGE_FIELD_STYLING_KEYS = Object.freeze({
    alignment: 'alignment',
});

export const IMAGE_FIELD_ALIGNMENT_OPTIONS = Object.freeze({
    left: 'left',
    center: 'center',
    right: 'right',
});

export const INPUT_FIELD_KEYS = Object.freeze({
    fieldName: 'field_name',
    icon: 'icon_id',
    label: 'label',
    helpText: 'help_text',
    defaultValue: 'default_value',
    placeholder: 'placeholder',
    isRequired: 'required',
});

export const DROPDOWN_FIELD_KEYS = Object.freeze({
    fieldName: 'field_name',
    icon: 'icon_id',
    label: 'label',
    isRequired: 'required',
    helpText: 'help_text',
    defaultValue: 'default_value',
    placeholder: 'placeholder',
    options: 'options',
});

export const HIDDEN_FIELD_KEYS = Object.freeze({
    fieldName: 'field_name',
});

export const RICH_TEXT_FIELD_KEYS = Object.freeze({
    fieldName: 'field_name',
    content: 'content',
});

export const ICONS = Object.freeze({
    [FIELD_TYPES.singleline]: 'abc',
    [FIELD_TYPES.multiline]: 'paragraph',
    [FIELD_TYPES.checkbox]: 'checkbox-squared',
    [FIELD_TYPES.dropdown]: 'dropdown',
    [FIELD_TYPES.hidden]: 'hidden',
    [FIELD_TYPES.number]: '123',
    [FIELD_TYPES.email]: 'message',
    [FIELD_TYPES.richText]: 'text-align-left',
    [FIELD_TYPES.image]: 'image',
});

export const FIELD_TYPES_LABELS = Object.freeze({
    [FIELD_TYPES.singleline]: 'Single-line text',
    [FIELD_TYPES.multiline]: 'Multi-line text',
    [FIELD_TYPES.checkbox]: 'Checkbox',
    [FIELD_TYPES.dropdown]: 'Select',
    [FIELD_TYPES.hidden]: 'Hidden Field',
    [FIELD_TYPES.number]: 'Number',
    [FIELD_TYPES.email]: 'Email',
    [FIELD_TYPES.richText]: 'Rich Text',
    [FIELD_TYPES.image]: 'Image',
});

export const DEFAULT_VALUES_DICT = Object.freeze({
    [FIELD_TYPES.checkbox]: {
        [CHECKBOX_FIELD_KEYS.fieldName]: '',
        [CHECKBOX_FIELD_KEYS.label]: '',
        [CHECKBOX_FIELD_KEYS.isRequired]: true,
        [CHECKBOX_FIELD_KEYS.defaultValue]: true,
    },
    [FIELD_TYPES.singleline]: {
        [INPUT_FIELD_KEYS.fieldName]: '',
        [INPUT_FIELD_KEYS.icon]: null,
        [INPUT_FIELD_KEYS.label]: '',
        [INPUT_FIELD_KEYS.helpText]: null,
        [INPUT_FIELD_KEYS.defaultValue]: null,
        [INPUT_FIELD_KEYS.placeholder]: null,
        [INPUT_FIELD_KEYS.isRequired]: true,
    },
    [FIELD_TYPES.number]: {
        [INPUT_FIELD_KEYS.fieldName]: '',
        [INPUT_FIELD_KEYS.icon]: null,
        [INPUT_FIELD_KEYS.label]: '',
        [INPUT_FIELD_KEYS.helpText]: null,
        [INPUT_FIELD_KEYS.defaultValue]: null,
        [INPUT_FIELD_KEYS.placeholder]: null,
        [INPUT_FIELD_KEYS.isRequired]: true,
    },
    [FIELD_TYPES.email]: {
        [INPUT_FIELD_KEYS.fieldName]: '',
        [INPUT_FIELD_KEYS.icon]: null,
        [INPUT_FIELD_KEYS.label]: '',
        [INPUT_FIELD_KEYS.helpText]: null,
        [INPUT_FIELD_KEYS.defaultValue]: null,
        [INPUT_FIELD_KEYS.placeholder]: null,
        [INPUT_FIELD_KEYS.isRequired]: false,
    },
    [FIELD_TYPES.dropdown]: {
        [DROPDOWN_FIELD_KEYS.fieldName]: '',
        [DROPDOWN_FIELD_KEYS.icon]: null,
        [DROPDOWN_FIELD_KEYS.helpText]: null,
        [DROPDOWN_FIELD_KEYS.label]: '',
        [DROPDOWN_FIELD_KEYS.placeholder]: null,
        [DROPDOWN_FIELD_KEYS.isRequired]: true,
        [DROPDOWN_FIELD_KEYS.options]: [],
        [DROPDOWN_FIELD_KEYS.defaultValue]: null,
    },
    [FIELD_TYPES.multiline]: {
        [INPUT_FIELD_KEYS.fieldName]: '',
        [INPUT_FIELD_KEYS.icon]: null,
        [INPUT_FIELD_KEYS.label]: '',
        [INPUT_FIELD_KEYS.helpText]: null,
        [INPUT_FIELD_KEYS.defaultValue]: null,
        [INPUT_FIELD_KEYS.placeholder]: null,
        [INPUT_FIELD_KEYS.isRequired]: true,
    },
    [FIELD_TYPES.hidden]: {
        [HIDDEN_FIELD_KEYS.fieldName]: '',
    },
    [FIELD_TYPES.richText]: {
        [RICH_TEXT_FIELD_KEYS.fieldName]: '',
        [RICH_TEXT_FIELD_KEYS.content]: '',
    },
    [FIELD_TYPES.image]: {
        [IMAGE_FIELD_KEYS.fieldName]: '',
        [IMAGE_FIELD_KEYS.content]: {
            [IMAGE_FIELD_CONTENT_KEYS.downloadUrl]: null,
            [IMAGE_FIELD_CONTENT_KEYS.sid]: '',
        },
        [IMAGE_FIELD_KEYS.styling]: {
            [IMAGE_FIELD_STYLING_KEYS.alignment]: IMAGE_FIELD_ALIGNMENT_OPTIONS.center,
        },
    },
});

export const REQUIRED_FIELDS = {
    [INPUT_FIELD_KEYS.fieldName]: 'Field name',
    [INPUT_FIELD_KEYS.label]: 'Field label',
    [DROPDOWN_FIELD_KEYS.options]: 'Values',
};

export const GOOGLE_CAMPAIGN_FIELDS_NAMES = [
    'utm_id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
];

export const CONFIG_FIELDS_PANEL_DATA = {
    otherNewFields: {
        title: 'Setup Field',
        description: 'Next, define the following values to finalize your field',
    },
    otherFields: {
        title: 'Edit Existing Field',
        description: 'You can edit existing settings, archive or delete field',
    },
    hiddenFields: {
        title: 'Hidden Field',
        description: (
            <>
                Use construction like <b>email=email@nimble.com.</b> Where <b>email</b> is field name &{' '}
                <b>email@nimble.com</b> is field value. You’ll see this construction on webform link. Don&apos;t use
                spaces or punctuation. Example, phone_number, address_1.
            </>
        ),
    },
};

export const getLists = (formFields) => {
    const HIDDEN = 'hidden';
    const OTHER = 'other';
    const GOOGLE = 'google';

    const grouped = R.groupBy((field) => {
        if (GOOGLE_CAMPAIGN_FIELDS_NAMES.includes(field.field_name)) {
            return GOOGLE;
        }

        if (field.field_type === FIELD_TYPES.hidden) {
            return HIDDEN;
        }

        return OTHER;
    })(formFields);

    const hiddenFields = grouped[HIDDEN] || [];
    const otherFields = grouped[OTHER] || [];
    const googleFields = grouped[GOOGLE] || [];

    return {hiddenFields, otherFields, googleFields};
};

export const FORM_DESCRIPTION_ID = '__form_description__';
export const FORM_CONFIRM_BUTTON_ID = '__confirm-button__';
export const FORM_NIMBLE_LABEL_ID = '__nimble-label__';
export const FORM_CAMPAIGN_ID = '__form-campaign__';
export const FORM_LOGO_ID = '__logo__';
