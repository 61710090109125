import React from 'react';

const SvgHeader = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <path
            fill={color}
            transform={`scale(${width / 24})`}
            d="M7.667 10.556h6.5V8.389h-6.5zM6.944 18.5a1.4 1.4 0 0 1-1.02-.424 1.4 1.4 0 0 1-.424-1.02V6.944q0-.596.424-1.02a1.4 1.4 0 0 1 1.02-.424h10.112q.596 0 1.02.424.424.425.424 1.02v10.112q0 .596-.424 1.02a1.4 1.4 0 0 1-1.02.424zm0-1.444h10.112V6.944H6.944z"
        />
    </svg>
);
export default SvgHeader;
