import numeral from 'numeral';

const passwordRegExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])^[\S]*$/;
const minPasswordLength = 8;
const maxPasswordLength = 32;
const notValidEmailTip = 'Enter valid email address';
const notValidFullNameTip = 'Enter First name and Last name';

/**
 * Format large numbers into a more readable string.
 * Transforms input numbers to a string representation with 'k' for thousands,
 * 'm' for millions, and 'b' for billions.
 * For numbers less than 10,000, it returns the number as a string with thousands separated by comma.
 *
 * - shortenAmount(100) returns '100'
 * - shortenAmount(1234) returns '1,234'
 * - shortenAmount(10000) returns '10.0k'
 * - shortenAmount(1000000) returns '1.0m'
 * - shortenAmount(123456789) returns '123.5m'
 * - shortenAmount(5000000000) returns '5.0b'
 * @param {number} amount - The large numerical value to be formatted
 * @returns {string} - Returns the formatted number as a string
 */
const shortenAmount = (amount) => numeral(amount).format(amount >= 10000 ? '0,000.0a' : '0,000');

/**
 * @param {string} domain - domain
 * @returns {boolean} - A flag that indicates whether the domain is valid
 */
const isValidDomain = (domain) => {
    const domainRegExp = new RegExp(
        '^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|' +
            '([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|' +
            '([a-zA-Z0-9][-_.a-zA-Z0-9]{0,61}[a-zA-Z0-9]))\\.' +
            '([a-zA-Z]{2,13}|[a-zA-Z0-9-]{2,30}.[a-zA-Z]{2,3})$',
    );

    return domainRegExp.test(domain);
};

/**
 * @param {string} pass - password
 * @returns {boolean} - true if password is valid
 */
const isValidPassword = (pass) =>
    !!pass &&
    !!pass.length &&
    pass.length >= minPasswordLength &&
    pass.length <= maxPasswordLength &&
    passwordRegExp.test(pass);

/**
 * Check if the url is a valid protocol url.
 * Used to validate the url of the clickable elements on ui.
 * A valid protocol url is a url that starts with http, https, mailto, tel, or callto.
 * @param {string} value - The url of the button value
 * @returns {boolean} - A flag that indicates whether the url is valid
 */
const isValidProtocolUrl = (value) => {
    const validProtocols = /^(https?:\/\/|mailto:|tel:|callto:)/;
    const hasDot = /^.+\..+$/;
    return hasDot.test(value) && validProtocols.test(value);
};

/**
 * Get the invalid password tip.
 * @param {string} pass - The password
 * @returns {string} - The invalid password tip
 */
const getInvalidPasswordTip = (pass) => {
    let tip = '';

    if (!isValidPassword(pass)) {
        tip = 'Password must have at least 1 lowercase character, 1 uppercase characters, 1 number.';
    }

    if (pass.length < minPasswordLength) {
        tip = 'Password must have at least 8 characters';
    }

    if (pass.length > maxPasswordLength) {
        tip = 'Password must have less than 33 characters.';
    }

    return tip;
};

export const string = {
    shortenAmount,
    isValidDomain,
    isValidProtocolUrl,
    isValidPassword,
    getInvalidPasswordTip,
    notValidEmailTip,
    notValidFullNameTip,
    passwordRegExp,
    minPasswordLength,
    maxPasswordLength,
};
