const hexToRgb = (hex) => {
    const normalizedHex = hex.length === 4 ? `${hex}${hex.slice(1, 4)}` : hex;
    const r = parseInt(normalizedHex.slice(1, 3), 16);
    const g = parseInt(normalizedHex.slice(3, 5), 16);
    const b = parseInt(normalizedHex.slice(5, 7), 16);

    return [r, g, b];
};

const hexToRgba = (hex, opacity = 1) => {
    const [r, g, b] = hexToRgb(hex);
    return `rgba(${r},${g},${b},${opacity})`;
};

const rgbToHex = (r, g, b) => {
    const toHex = (num) => {
        const hex = num.toString(16);
        return hex.length > 1 ? hex : `0${hex}`;
    };
    return '#' + toHex(r) + toHex(g) + toHex(b);
};

const rgbStringToHex = (rgbStrColor) => {
    const [r, g, b] = rgbStrColor.replace('rgb(', '').replace(')', '').split(',').map(Number);
    return rgbToHex(r, g, b);
};

const isDark = (hexColor) => {
    const [r, g, b] = hexToRgb(hexColor);
    return r * 0.299 + g * 0.587 + b * 0.114 > 186;
};

const pickColorBasedOnBgColor = ({bgColor, lightColor = '#FFF', darkColor = '#000'}) => {
    return isDark(bgColor) ? darkColor : lightColor;
};

const isHexColor = (color) => {
    return /^#[0-9A-Fa-f]{6}$/.test(color);
};

const colorNumberToHex = (colorNumber, defaultColor = '#00839D') => {
    const COLORS = {
        0: '#C67765',
        1: '#2D2D2D',
        2: '#A2004E',
        3: '#B60000',
        4: '#765D02',
        5: '#197B00',
        6: '#00839D',
        7: '#0C2DA4',
        8: '#750492',
        9: '#AFAFAF',
        10: '#E90D77',
        11: '#FF2626',
        12: '#FAC610',
        13: '#56CF37',
        14: '#14CAEE',
        15: '#2A56F2',
        16: '#CE39F3',
        17: '#D7D7D7',
        18: '#F284B9',
        19: '#E78484',
        20: '#E1CB7C',
        21: '#9EE48C',
        22: '#80DDEF',
        23: '#7E97ED',
        24: '#DA9EE9',
    };

    return COLORS[colorNumber] || defaultColor;
};

const colorOptionsArray = Object.freeze([
    ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#E0E0E0', '#E8E8E9', '#F1F1F1', '#FFFFFF'],
    ['#96040B', '#FC0D1B', '#FD9827', '#FFFD38', '#29FD2F', '#2DFFFE', '#4D88E5', '#0B24FB', '#9825FB', '#FEE8F2'],
    ['#FDDCEB', '#F3CCCD', '#FBE5CE', '#FFF1CE', '#D9EAD4', '#D0E0E3', '#CADAF7', '#D0E2F2', '#D9D2E8', '#E9D1DC'],
    ['#DB7E6E', '#E8999A', '#F8CA9F', '#FEE49D', '#B7D6AA', '#A3C4C9', '#A5C3F2', '#A0C6E6', '#B4A8D5', '#D4A7BD'],
    ['#CA422D', '#DE6768', '#F4B171', '#FED86F', '#94C380', '#78A5AE', '#6FA0E8', '#71A9DA', '#8E7EC1', '#C17CA0'],
    ['#A41E0E', '#CA0813', '#E49042', '#F0C143', '#6CA754', '#47818D', '#407AD5', '#4186C4', '#6751A5', '#A54F79'],
    ['#842113', '#97040C', '#B25F1A', '#BE8F1F', '#3A7524', '#174F5B', '#1959C9', '#125592', '#352073', '#731D47'],
    ['#5A1004', '#650205', '#773F0F', '#7E5F11', '#284D17', '#0E343C', '#1F4785', '#0B3862', '#20154C', '#4B1330'],
]);

const colorsWithBorders = Object.freeze(['#E8E8E9', '#F1F1F1', '#FFFFFF']);

export const COLORS = {
    hexToRgb,
    hexToRgba,
    rgbStringToHex,
    rgbToHex,
    pickColorBasedOnBgColor,
    colorNumberToHex,
    isHexColor,
    colorOptionsArray,
    colorsWithBorders,
};
