import React from 'react';

const SvgFourColumns = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g opacity="0.3" transform={`scale(${width / 24})`} fill={color}>
            <path d="M5 6a1 1 0 0 1 1-1h1.75v14H6a1 1 0 0 1-1-1zM8.75 5h2.75v14H8.75zM12.5 5h2.75v14H12.5zM16.25 5H18a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-1.75z" />
        </g>
    </svg>
);
export default SvgFourColumns;
