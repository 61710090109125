import React from 'react';
import {BUTTON_APPEARANCES, BUTTON_FORMS} from 'WEBFORMS/config';

const SubmitButton = ({label, type = BUTTON_APPEARANCES.primary, disabled, form, isLoading}) => {
    const typeClassName = `__${type}`;
    const loadingClassName = isLoading ? '__loading' : '';
    const tabIndex = disabled ? -1 : 1;

    const positionClassName = {
        [BUTTON_FORMS.left]: '__left',
        [BUTTON_FORMS.center]: '__center',
        [BUTTON_FORMS.right]: '__right',
        [BUTTON_FORMS.stretch]: '__stretch',
    }[form];

    return (
        <div className={`submit-button-wrapper ${positionClassName}`}>
            <button
                type="submit"
                className={`submit-button ${typeClassName} ${loadingClassName}`}
                aria-disabled={disabled}
                data-disabled={disabled}
                tabIndex={tabIndex}>
                <span className="submit-button__label">{label}</span>
            </button>
        </div>
    );
};

export default SubmitButton;
