import React from 'react';

const SvgDrag = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g transform={`scale(${width / 24})`}>
            <path
                fill={color}
                d="M11.2 16.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5 1.5.7 1.5 1.5m-1.4-6c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5m0-4.5c-.8 0-1.5.7-1.5 1.5S9 9 9.8 9s1.5-.7 1.5-1.5S10.6 6 9.8 6m4.4 3c.8 0 1.5-.7 1.5-1.5S15 6 14.2 6s-1.5.7-1.5 1.5.7 1.5 1.5 1.5m0 1.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.6-1.5-1.5-1.5m0 4.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.6-1.5-1.5-1.5"
            />
        </g>
    </svg>
);

export default SvgDrag;
