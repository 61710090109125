import React from 'react';

/**
 * SVG icon with customizable color.
 * @param {object} props - The component props.
 * @param {string} props.color - The color of the SVG icon.
 * @param {number} props.width - The width of the SVG icon.
 * @param {number} props.height - The height of the SVG icon.
 * @returns {React.ReactElement} Rendered SVG icon.
 */
const SvgInfo = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g transform={`scale(${width / 24})`}>
            <path
                fill={color}
                d="M15 5.625c-5.125 0-9.375 4.25-9.375 9.375s4.25 9.375 9.375 9.375 9.375-4.25 9.375-9.375S20.125 5.625 15 5.625Zm1 14.125h-1.875v-5.625H16v5.625Zm0-7.5h-1.875v-1.875H16v1.875Z"
            />
        </g>
    </svg>
);

export default SvgInfo;
