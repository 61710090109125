import {array} from './array/_array';
import {fonts} from './fonts/_fonts';
import {object} from './object/_object';
import {string} from './string/_string';

export const Utils = (() => {
    return {
        array,
        object,
        string,
        fonts,
    };
})();
