import {useCallback, useMemo, useState} from 'react';
import _ from 'underscore';

/**
 * @param {object} props - Component props.
 * @param {import('react').RefObject<HTMLFormElement | null>} props.formElementRef - Form element ref.
 * @returns {{isSubmitDisabled: boolean, isValidForm: () => boolean, validate: () => void}} - Returned object with form validation state and methods.
 */
export const useFormValidation = ({formElementRef}) => {
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    const validateForm = useCallback(
        (emptyOptionalCallback) => {
            if (!formElementRef.current) return false;

            formElementRef.current.classList.add('was-validated');

            const fields = Array.from(formElementRef.current.elements).filter((el) =>
                ['input', 'textarea', 'select'].includes(el.nodeName.toLowerCase()),
            );
            const requiredFields = fields.filter((el) => el.dataset.required === 'true');
            const invalidRequiredFields = requiredFields.filter((el) => el.dataset.invalid === 'true');

            const onlyCheckboxes = fields.every((el) => el.type === 'checkbox');
            const optionalFields = fields.filter((el) => el.dataset.required === 'false');
            const emptyOptionalFields = optionalFields.filter((el) => el.dataset.empty === 'true');

            if (
                requiredFields.length === 0 &&
                emptyOptionalFields.length === optionalFields.length &&
                !onlyCheckboxes
            ) {
                typeof emptyOptionalCallback === 'function' && emptyOptionalCallback(formElementRef.current);
            }

            const isInvalid = requiredFields.length
                ? Boolean(invalidRequiredFields.length)
                : (onlyCheckboxes && !emptyOptionalFields) || emptyOptionalFields.length === optionalFields.length;

            return !isInvalid;
        },
        [formElementRef],
    );

    const validate = useMemo(
        () =>
            _.debounce(() => {
                setIsSubmitDisabled(!validateForm());
            }, 100),
        [validateForm],
    );

    const isValidForm = useCallback(() => {
        const isValid = validateForm((formElement) => {
            formElement.classList.add('__invalid-form');
            setTimeout(() => {
                formElement.classList.remove('__invalid-form');
            }, 2000);
        });
        setIsSubmitDisabled(!isValid);
        return isValid;
    }, [validateForm]);

    return {
        isSubmitDisabled,
        isValidForm,
        validate,
    };
};
