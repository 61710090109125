import React from 'react';

const SvgDivider = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <path
            transform={`scale(${width / 24})`}
            fill={color}
            fillRule="evenodd"
            d="M18.375 17a1.5 1.5 0 0 1-1.5 1.5h-9.75a1.5 1.5 0 0 1-1.5-1.5v-2.25h1.5V17h9.75v-2.25h1.5zM5.625 7a1.5 1.5 0 0 1 1.5-1.5h9.75a1.5 1.5 0 0 1 1.5 1.5v2.25h-1.5V7h-9.75v2.25h-1.5z"
            clipRule="evenodd"
        />
        <path
            transform={`scale(${width / 24})`}
            fill={color}
            d="M18.956 13.336a1.8 1.8 0 0 1-1.331.544q-.581 0-1.05-.319a1.82 1.82 0 0 1-.675-.806H8.1a1.82 1.82 0 0 1-.675.806 1.83 1.83 0 0 1-1.05.319 1.8 1.8 0 0 1-1.331-.544 1.8 1.8 0 0 1-.544-1.331q0-.788.544-1.331a1.8 1.8 0 0 1 1.331-.544q.581 0 1.05.319.47.318.675.806h7.8q.206-.488.675-.806a1.83 1.83 0 0 1 1.05-.319q.788 0 1.331.544.544.543.544 1.331t-.544 1.331"
        />
        <path
            transform={`scale(${width / 24})`}
            fill={color}
            d="M18.956 13.336a1.8 1.8 0 0 1-1.331.544q-.581 0-1.05-.319a1.82 1.82 0 0 1-.675-.806H8.1a1.82 1.82 0 0 1-.675.806 1.83 1.83 0 0 1-1.05.319 1.8 1.8 0 0 1-1.331-.544 1.8 1.8 0 0 1-.544-1.331q0-.788.544-1.331a1.8 1.8 0 0 1 1.331-.544q.581 0 1.05.319.47.318.675.806h7.8q.206-.488.675-.806a1.83 1.83 0 0 1 1.05-.319q.788 0 1.331.544.544.543.544 1.331t-.544 1.331"
        />
    </svg>
);
export default SvgDivider;
