import React from 'react';
import DOMPurify from 'dompurify';

/**
 * RichTextField component for rendering the rich text field.
 * @param {object} props - The properties passed to the component.
 * @param {string} [props.content] - The content for the rich text field.
 * @returns {React.ReactElement} The rendered rich text input field.
 */
const RichTextField = ({content = ''}) => {
    return (
        <div className="rich-text-field field-box" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content)}} />
    );
};

export default RichTextField;
