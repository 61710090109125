import React from 'react';

const SvgFormatText = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <path
            transform={`scale(${width / 24})`}
            fill={color}
            d="M6.8 16.1v1.5h10.5v-1.5zm3.3-3.1h3.8l.7 1.7h1.6l-3.6-8.2h-1.1l-3.6 8.2h1.6zM12 7.9l1.4 3.8h-2.8z"
        />
    </svg>
);
export default SvgFormatText;
