import React from 'react';

const SvgButton = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <path
            fill={color}
            transform={`scale(${width / 24})`}
            d="M6 16.5q-.618 0-1.06-.44A1.45 1.45 0 0 1 4.5 15V9q0-.619.44-1.06Q5.383 7.5 6 7.5h12q.62 0 1.06.44.44.442.44 1.06v6q0 .619-.44 1.06-.44.44-1.06.44zM6 15h12V9H6zm2.438-.75h1.124v-1.687h1.688v-1.126H9.563V9.75H8.437v1.688H6.75v1.124h1.688z"
        />
    </svg>
);
export default SvgButton;
