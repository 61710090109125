import React from 'react';

const SvgSpacer = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <path
            transform={`scale(${width / 24})`}
            fill={color}
            d="m7.5 18-3-3 1.05-1.05 1.2 1.163V8.888l-1.2 1.162L4.5 9l3-3 3 3-1.05 1.05-1.2-1.163v6.226l1.2-1.163L10.5 15zm4.5-.75v-1.5h7.5v1.5zm0-4.5v-1.5h7.5v1.5zm0-4.5v-1.5h7.5v1.5z"
        />
    </svg>
);
export default SvgSpacer;
