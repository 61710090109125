import React from 'react';

const SvgPaddingLeft = ({width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <path
            fill="#D9DFFB"
            transform={`scale(${width / 24})`}
            d="M18 17a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1z"
        />
        <mask id="padding-left_svg__a" fill="#fff">
            <path d="M8 16H6V8h2z" />
        </mask>
        <path
            fill="#39A4F3"
            d="M8 16v2h2v-2zm-2 0H4v2h2zm0-8V6H4v2zm2 0h2V6H8zm0 6H6v4h2zm0 2V8H4v8zm-2-6h2V6H6zm0-2v8h4V8z"
            mask="url(#padding-left_svg__a)"
        />
    </svg>
);
export default SvgPaddingLeft;
