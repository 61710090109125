import * as R from 'ramda';

const getItemsByPropValue = ({array, prop, value}) => R.filter(R.propSatisfies(R.equals(value), prop), array);

/**
 * Retrieves an item from an array based on the provided id.
 * @param {object} params - The parameters for the function.
 * @param {any[]} params.array - The array from which to retrieve the item.
 * @param {string|number} params.id - The id of the item to retrieve.
 * @param {string|string[]} [params.idKey] - The key or path to the id in the objects of the array.
 * @returns {object | undefined} The found item, or undefined if no item was found.
 */
const getItemById = ({array, id, idKey = 'id'}) =>
    R.find(Array.isArray(idKey) ? R.pathEq(id, idKey) : R.propEq(id, idKey), array);

const getItemsByIsArchivedProp = ({array, isArchived}) =>
    getItemsByPropValue({array, prop: 'is_archived', value: isArchived});

const indexByProp = ({array, prop}) => R.indexBy(R.prop(prop), array);

const findIndexByPropValue = ({array, prop, value}) => R.findIndex(R.propEq(value, prop), array);

/**
 * Sorts an array by a property of string or number type.
 * @param {object} params - The parameters for the function.
 * @param {object[]} params.array - The array to sort.
 * @param {string} params.prop - The property to sort by.
 * @param {boolean} [params.isDescending] - Whether to sort in descending order.
 * @param {boolean} [params.isCaseSensitive] - Whether to sort in case-sensitive order.
 * @returns {object[]} The sorted array.
 */
const sortByProp = ({array, prop, isDescending = false, isCaseSensitive = false}) => {
    const getComparator = () => {
        const direction = isDescending ? R.descend : R.ascend;
        const propValue = R.prop(prop);
        const transformProp = R.pipe(propValue, (value) => (typeof value === 'string' ? R.toLower(value) : value));
        return isCaseSensitive ? direction(propValue) : direction(transformProp);
    };
    const comparator = getComparator();
    return R.sort(comparator, array);
};

/**
 * Appends an item to an array if it doesn't exist, or updates it if it does.
 * @param {object} params - The parameters for the function.
 * @param {[]} params.array - The array into which to append or update the item.
 * @param {string|number} params.id - The id of the item to append or update.
 * @param {string|string[]} [params.idKey] - The key or path to the id in the objects of the array.
 * @param {object} params.item - The item to append or update.
 * @returns {Array<object>} A new array with the item appended or updated.
 */
const upsertItemById = ({array, id, idKey = 'id', item}) => {
    const existingItem = getItemById({array, id, idKey});
    if (existingItem) {
        return updateItemById({array, id, idKey, update: item});
    }

    return [...array, item];
};

/**
 * Updates an item in an array based on the provided id.
 * @param {object} params - The parameters for the function.
 * @param {[]} params.array - The array in which to update the item.
 * @param {string|number} params.id - The id of the item to update.
 * @param {string|string[]} [params.idKey] - The key or path to the id in the objects of the array.
 * @param {object} params.update - The object to merge with the existing item.
 * @returns {Array<any>} A new array with the specified item updated.
 */
const updateItemById = ({array, id, idKey = 'id', update}) =>
    R.map(
        R.ifElse(Array.isArray(idKey) ? R.pathEq(id, idKey) : R.propEq(id, idKey), R.mergeDeepLeft(update), R.identity),
        array,
    );

/**
 * Removes an item from an array based on the provided id.
 * @param {object} params - The parameters for the function.
 * @param {[]} params.array - The array from which to remove the item.
 * @param {string|number} params.id - The id of the item to remove.
 * @param {string|string[]} [params.idKey] - The key or path to the id in the objects of the array.
 * @returns {[]} A new array with the specified item removed.
 */
const removeItemById = ({array, id, idKey = 'id'}) =>
    R.reject(Array.isArray(idKey) ? R.pathEq(id, idKey) : R.propEq(id, idKey), array);

const getFirstItemPropValue = ({array, prop}) => R.path([0, ...prop.split('.')], array);

const setItemAtPositionAndFilterDuplicates = ({item, idKey = 'id', index, list}) => {
    const existingField = getItemById({
        array: list,
        id: item[idKey],
        idKey,
    });

    const copiedList = list.slice(0, index);
    copiedList[index] = item;
    Array.prototype.push.apply(copiedList, list.slice(index, list.length));

    if (existingField) {
        return copiedList.filter((listItem, idx) => listItem[idKey] !== item[idKey] || index === idx);
    }

    return copiedList;
};

export const array = {
    getItemsByPropValue,
    getItemById,
    getItemsByIsArchivedProp,
    indexByProp,
    findIndexByPropValue,
    sortByProp,
    updateItemById,
    upsertItemById,
    removeItemById,
    getFirstItemPropValue,
    setItemAtPositionAndFilterDuplicates,
};
