import React from 'react';

const SvgImageAndText = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <path
            fill={color}
            transform={`scale(${width / 24})`}
            d="M5.5 15.611V8.39h7.222v7.222zm1.444-1.444h4.334V9.833H6.944zM5.5 6.944V5.5h13v1.444zm8.667 2.89V8.388H18.5v1.444zm0 2.888v-1.444H18.5v1.444zm0 2.89v-1.445H18.5v1.444zM5.5 18.5v-1.444h13V18.5z"
        />
    </svg>
);
export default SvgImageAndText;
