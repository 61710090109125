import React from 'react';

const SvgPlusWithCircle = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g transform={`scale(${width / 24})`}>
            <path
                d="M12.8 8.20001H11.3V11.2H8.3V12.7H11.3V15.7H12.8V12.7H15.8V11.2H12.8V8.20001ZM12 4.5C7.9 4.5 4.5 7.9 4.5 12C4.5 16.1 7.9 19.5 12 19.5C16.1 19.5 19.5 16.1 19.5 12C19.5 7.9 16.1 4.5 12 4.5ZM12 18C8.7 18 6 15.3 6 12C6 8.7 8.7 6 12 6C15.3 6 18 8.7 18 12C18 15.3 15.3 18 12 18Z"
                fill={color}
            />
        </g>
    </svg>
);

export default SvgPlusWithCircle;
