import React from 'react';

const SvgTableSettings = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <path
            fill={color}
            transform={`scale(${width / 24})`}
            d="M18 6H6c-.8 0-1.5.7-1.5 1.5v9c0 .8.7 1.5 1.5 1.5h12c.8 0 1.5-.7 1.5-1.5v-9c0-.8-.7-1.5-1.5-1.5M9.6 16.5H6v-9h3.6zm4 0h-3.2v-9h3.2zm4.4 0h-3.6v-9H18z"
        />
    </svg>
);
export default SvgTableSettings;
