import React from 'react';

const SvgPaddingRight = ({width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <path
            fill="#D9DFFB"
            transform={`scale(${width / 24})`}
            d="M18 17a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1z"
        />
        <mask id="padding-right_svg__a" fill="#fff">
            <path d="M18 16h-2V8h2z" />
        </mask>
        <path
            fill="#39A4F3"
            d="M18 16v2h2v-2zm-2 0h-2v2h2zm0-8V6h-2v2zm2 0h2V6h-2zm0 6h-2v4h2zm0 2V8h-4v8zm-2-6h2V6h-2zm0-2v8h4V8z"
            mask="url(#padding-right_svg__a)"
        />
    </svg>
);
export default SvgPaddingRight;
