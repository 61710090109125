//!!!Do not change the keys and values, as this may affect the user's display
export const GLOBAL_CSS_VARIABLES = Object.freeze({
    formBgColor: '--form-background-color',
    bgColorBehindForm: '--background-color-behind-form',
    formBorderRadius: '--form-border-radius',
    formShadowSize: '--form-shadow-size',
    formShadowRGB: '--form-shadow-rgb',
    formShadowOpacity: '--form-shadow-opacity',
    formMinWidth: '--form-min-width',
    formMaxWidth: '--form-max-width',
    formMargin: '--form-margin',
    accentColor: '--accent-color',
    btnTextColor: '--submit-btn-text-color',
    fieldHeight: '--field-height',
    dropdownHeight: '--dropdown-height',
    fieldFontSize: '--field-font-size',
    fieldFontWeight: '--field-font-weight',
    fieldFontFamily: '--field-font-family',
    fieldFontFamilyBackup: '--field-font-family-backup',
    fieldFontStyle: '--field-font-style',
    fontImportUrl: '--font-import-url',
    fieldVerticalPadding: '--field-vertical-padding',
    dropdownVerticalPadding: '--dropdown-vertical-padding',
    fieldBorderWidth: '--field-border-width',
    fieldBorderRadius: '--field-border-radius',
    fieldShadowSize: '--field-shadow-size',
    fieldShadowRGB: '--field-shadow-rgb',
    fieldShadowOpacity: '--field-shadow-opacity',
    textFontSizeAboveBelowField: '--text-font-size-above-below-field',
    textLineHeightAboveBelowField: '--text-line-height-above-below-field',
    fieldLabelExtraMargin: '--field-label-extra-margin',
    distanceBetweenFields: '--distance-between-fields',
    fieldActiveLabelFontSize: '--field-active-label-font-size',
    fieldBorderColorPassive: '--field-border-color-passive',
    fieldBgColorPassive: '--field-bg-color-passive',
    fieldBgColorError: '--field-bg-color-error',
    fieldBorderColorError: '--field-border-color-error',
    fieldBorderColorActive: '--field-border-color-active',
    fieldBgColorActive: '--field-bg-color-active',
    fieldFontColor: '--field-font-color',
    checkboxSize: '--checkbox-size',
    multilineIconTopOffset: '--field-multiline-icon-top-offset',
    formDescriptionAlignment: '--form-description-alignment',
    formDescriptionFontSize: '--form-description-font-size',
    formTopDescriptiveSectionDirection: '--form-top-descriptive-section-direction',
    formDescriptionPreviewItemMargin: '--form-description-preview-item-margin',
});
