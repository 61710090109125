import React from 'react';

const SvgThreeColumns = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g opacity="0.3" transform={`scale(${width / 24})`} fill={color}>
            <path d="M5 6a1 1 0 0 1 1-1h3v14H6a1 1 0 0 1-1-1zM10 5h4v14h-4zM15 5h3a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-3z" />
        </g>
    </svg>
);
export default SvgThreeColumns;
